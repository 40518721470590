import './App.css';
import React from 'react';

const emotionDictionary = {
  ':)': ['happy-background', "You don't have to rub it in."],
  ':/': ['meh-background', "Don't we all"],
  ':(': ['sad-background', 'Just smile 4head :)'],
};

function App() {
  const [emotion, setEmotion] = React.useState('');
  const emotionClass = emotionDictionary[emotion];

  return (
    <div className='App'>
      <header
        className={`App-header ${
          emotionClass ? emotionClass[0] : 'default-background'
        }`}
      >
        <p>
          {emotionClass ? (
            emotionClass[1]
          ) : (
            <p>
              {' '}
              How are you feeling today?
              <br />
              :) , :/ or :( ?
            </p>
          )}
        </p>
        <div>
          <input
            value={emotion}
            type='textarea'
            onChange={(e) => setEmotion(e.target.value)}
          />
          <button onClick={() => setEmotion('')}> clear</button>
        </div>
      </header>
    </div>
  );
}

export default App;
